import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const schema = {
  "@context": "https://schema.org",
  "@type": "Winery",
  "name": "Archibald James Wine & Ciderworks",
  "image": "https://archibaldjames.com/seo.jpg",
  "@id": "",
  "url": "https://archibaldjames.com",
  "telephone": "+15096615010",
  "hasMenu": "https://archibaldjames.com/menu",
  "servesCuisine": "Winery",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "617 Front St",
    "addressLocality": "Leavenworth",
    "addressRegion": "WA",
    "postalCode": "98826",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 47.5939523,
    "longitude": -120.6648052
  },
  "hasMap": "https://g.page/ArchibaldJamesWine",
  "sameAs": "https://archibaldjames.com"
};
export const _frontmatter = {};
const layoutProps = {
  schema,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO schemaMarkup={schema} title="Leavenworth Wine & Cider Tasting" description="Leavenworth Wine Tasting & Cider Tasting at Archibald James" mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "wine--cider-tasting"
      }}>{`Wine & Cider Tasting`}</h1>
      <h2 {...{
        "id": "were-moving-our-tasting-room-to-wenatchees-pybus-public-market"
      }}>{`We're moving our tasting room to `}<a parentName="h2" {...{
          "href": "/wenatchee"
        }}>{`Wenatchee's Pybus Public Market`}</a>{`.`}</h2>
      <p>{`Not nearby? `}<a parentName="p" {...{
          "href": "/club"
        }}>{`Join our club`}</a>{` and have our latest and greatest delivered to your door.`}</p>
      <p>{`Archibald James Wine & Ciderworks, 617 Front St, Leavenworth, WA`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      